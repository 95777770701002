import {Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Typography} from 'antd'
import {DefaultOptionType} from 'antd/lib/select'
import moment from 'moment'
import {memo, useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import Swal from 'sweetalert2'
import {DeviceType, getDeviceType} from '../../../../../../enum/deviceType.enum'
import {Role, useAuth} from '../../../../../modules/auth'
import {useQueryResponse as useQueryResponseElectronicBoard} from '../../../bangdientu-list/core/QueryResponseProviderBDT'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {DeviceInfo} from '../../core/_models'

import '../../../../../../_metronic/assets/sass/DeviceInfoListSearchComponent.scss'
import compareLabelMatch from '../../../../../../untils/compare-label-match'
import {getDeviceGroupById} from '../../../../nhomthietbi/nhomthietbi-list/core/_requests'
import {
  createDeviceInfo,
  getDeviceById,
  getListDeviceGroupByUsername,
  getListDistrictByCityId,
  getListManufacture,
  getListWardByDistrictId,
  getProvinces,
  updateDeviceInfo,
} from '../../core/_requests'
import React from 'react'
import {ValidationError} from 'yup'

const ServiceTypeList: DefaultOptionType[] = [
  {label: 'Tiêu chuẩn', value: 0},
  {label: 'Nâng cao', value: 1},
]

// const IdCityHN = '01' // Tọa độ Bytech
// const DistrictIdHM = '008'
// const WardIdHL = '00337'

interface CreateDeviceModalProps {
  isModalOpen: boolean
  handleCancel: any
  id?: string
  isElectronicBoard: boolean
}

const LongitudeByTech = 105.83469069654059
const LatitudeByTech = 20.96784117378008

const DeviceModal = memo(
  ({isModalOpen, handleCancel, id, isElectronicBoard}: CreateDeviceModalProps) => {
    const [form] = Form.useForm()
    const {auth, currentUser} = useAuth()

    const isADMIN = currentUser?.Roles.find((item: Role) => {
      return item.Value === 999
    })

    const CityId = Form.useWatch('CityId', form)
    const DistrictId = Form.useWatch('DistrictId', form)
    const [cityId, setCityId] = useState('')
    const [districtId, setDistrictId] = useState('')
    const [CityName, setCityName] = useState('')
    const [DistrictName, setDistrictName] = useState('')
    const [WardName, setWardName] = useState('')

    const deviceTypeList = getDeviceType()
    const {refetch} = useQueryResponse()

    const {refetch: refetchElectronicBoard} = useQueryResponseElectronicBoard()

    const createDeviceMutation = useMutation({
      mutationFn: (payload: DeviceInfo) => createDeviceInfo(auth?.configs, payload),
    })
    const updateDeviceMutation = useMutation({
      mutationFn: (payload: DeviceInfo) => updateDeviceInfo(auth?.configs, payload),
    })

    const handleOk = () => {
      form.validateFields().then(() => {
        const {CityId, DistrictId, WardId, Imei, ...payload} = form.getFieldsValue(true)
        if (CityName) {
          payload.Address = {
            CityName,
            DistrictName,
            WardName,
          }
        } else {
          payload.Address = {
            CityName: CityId,
            DistrictName: DistrictId,
            WardName: WardId,
          }
        }
        payload.Imei = Imei.replace(/\s+/g, '')
        id
          ? updateDeviceMutation
              .mutateAsync({Id: id, ...payload})
              .then(async () => {
                Swal.fire({
                  icon: 'success',
                  title: 'Cập nhật thiết bị thành công',
                  text: ' ',
                  showConfirmButton: false,
                  timer: 1500,
                })
                isElectronicBoard ? refetchElectronicBoard() : refetch()
                handleCancel()
              })
              .catch((error) =>
                Swal.fire({
                  icon: 'error',
                  title:
                    typeof error.response.data != 'object'
                      ? error.response.data
                      : 'Cập nhật thiết bị thất bại',
                  text: ' ',
                  confirmButtonText: 'Đóng lại',
                })
              )
          : createDeviceMutation
              .mutateAsync(payload)
              .then(async () => {
                Swal.fire({
                  icon: 'success',
                  title: 'Thêm thiết bị thành công',
                  text: ' ',
                  showConfirmButton: false,
                  timer: 1500,
                })
                isElectronicBoard ? refetchElectronicBoard() : refetch()

                handleCancel()
              })
              .catch((error) =>
                Swal.fire({
                  icon: 'error',
                  title:
                    typeof error.response.data != 'object'
                      ? error.response.data
                      : 'Thêm thiết bị thất bại',
                  text: ' ',
                  confirmButtonText: 'Đóng lại',
                })
              )
      })
    }

    const {data: manufactureList} = useQuery({
      queryKey: ['manufacture_list'],
      queryFn: () => getListManufacture(auth?.configs),
    })

    const {data: deviceList} = useQuery({
      queryKey: ['device_list'],
      queryFn: () => getListDeviceGroupByUsername(auth?.configs, currentUser?.UserName || ''),
    })

    const {data: cityList} = useQuery({
      queryKey: ['city_device_list'],
      queryFn: () => getProvinces(auth?.configs),
    })

    const {data: districtList} = useQuery({
      queryKey: ['district_device_list', cityId],
      queryFn: () => getListDistrictByCityId(auth?.configs, cityId),
      enabled: !!cityId,
    })

    const {data: wardList} = useQuery({
      queryKey: ['ward_device_list', cityId, districtId],
      queryFn: () => getListWardByDistrictId(auth?.configs, cityId as string, districtId as string),
      enabled: !!cityId && !!districtId,
    })

    useQuery({
      queryKey: ['device_detail_key', id],
      queryFn: () => getDeviceById(auth?.configs, id as string),
      enabled: !!id,
      onSuccess(data) {
        setCityId(cityList?.find((item) => item.CityName === data.Address?.CityName)?.CityId ?? '')
        form.setFieldsValue({
          Info: data.Info,
          DeviceType: data.DeviceType,
          GroupID: data.GroupID,
          Imei: data.Imei,
          Longitude: data.Longitude,
          CustomerPhone: data.CustomerPhone,
          ManufactureId: data.ManufactureId,
          Latitude: data.Latitude,
          ServiceType: data.ServiceType,
          CityId: data.Address?.CityName,
          DistrictId: data.Address?.DistrictName,
          WardId: data.Address?.WardName,
          CityName: data.Address?.CityName,
          DistrictName: data.Address?.DistrictName,
          WardName: data.Address?.WardName,
          InitTime: data.InitTime ? moment(data.InitTime) : null,
          WarrantyTime: data.WarrantyTime ? moment(data.WarrantyTime) : null,
        })
      },
    })

    useEffect(() => {
      if (CityId) {
        setCityId(cityList?.find((item) => item.CityId === CityId)?.CityId ?? '')
      }
    }, [cityList, CityId])

    useEffect(() => {
      if (DistrictId) {
        setDistrictId(
          districtList?.find((item) => item.DistrictId === DistrictId)?.DistrictId ?? ''
        )
      }
    }, [DistrictId, districtList])

    // const [submittable, setSubmittable] = React.useState<boolean>(false)

    // // Watch all values
    // const values = Form.useWatch([], form)

    // React.useEffect(() => {
    //   form
    //     .validateFields({validateOnly: true})
    //     .then(() => setSubmittable(true))
    //     .catch(() => setSubmittable(false))
    // }, [form, values])

    return (
      <Modal
        className='nguoi-dung-body modal-device'
        title={
          <Typography.Title level={3} style={{margin: 0}}>
            {id ? 'Chỉnh sửa thiết bị' : 'Thêm mới thiết bị'}
          </Typography.Title>
        }
        width={1000}
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose
        bodyStyle={{padding: '16px'}}
        footer={
          <div className='text-center mt-5' key='action'>
            <button
              type='reset'
              className='btn btn-outline-primary me-3 cancel'
              data-kt-users-modal-action='cancel'
              onClick={handleCancel}
            >
              Hủy bỏ
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              onClick={handleOk}
              disabled={
                !form.isFieldsTouched() ||
                form.getFieldsError().filter(({errors}) => errors.length).length > 0
              }
            >
              <span className='indicator-label'>{id ? 'Chỉnh sửa' : 'Thêm mới'}</span>
            </button>
          </div>
        }
      >
        <Form form={form} layout='vertical'>
          <Typography.Title level={5} style={{color: '#A6A6A6'}}>
            Thông tin thiết bị
          </Typography.Title>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                name='Info'
                label='Tên thiết bị'
                rules={[
                  {
                    required: true,
                    message: 'Tên thiết bị không được bỏ trống',
                    type: 'string',
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder='Nhập tên thiết bị' />
              </Form.Item>
              <Form.Item
                name='DeviceType'
                label='Loại thiết bị'
                initialValue={isElectronicBoard ? 2 : 0}
                rules={[
                  {
                    required: true,
                    message: 'Loại thiết bị không được bỏ trống',
                  },
                ]}
              >
                <Select
                  options={
                    isADMIN
                      ? (deviceTypeList as DefaultOptionType[])
                      : isElectronicBoard
                      ? (deviceTypeList.filter((item) =>
                          [DeviceType.DISPLAY].includes(item?.value as number)
                        ) as DefaultOptionType[])
                      : (deviceTypeList.filter(
                          (item) =>
                            ![DeviceType.DISPLAY, DeviceType.DISPLAY_FM].includes(
                              item?.value as number
                            )
                        ) as DefaultOptionType[])
                  }
                  placeholder='Chọn loại thiết bị'
                />
              </Form.Item>
              <Form.Item
                name='GroupID'
                label='Địa bàn phát'
                rules={[
                  {
                    required: true,
                    message: 'Địa bàn phát không được bỏ trống',
                  },
                ]}
              >
                <Select
                  defaultActiveFirstOption={true}
                  options={deviceList?.Data?.map((item) => ({
                    label: item.DeviceGroupName,
                    value: item.Id,
                  }))}
                  onChange={(value) => {
                    getDeviceGroupById(auth?.configs, value).then((data: any) => {
                      setCityName(data?.Address?.CityName ?? '')
                      setDistrictName(data?.Address?.DistrictName ?? '')
                      setWardName(data?.Address?.WardName ?? '')
                      form.setFieldsValue({
                        CityId: data.Address?.CityName,
                        DistrictId: data.Address?.DistrictName,
                        WardId: data.Address?.WardName,
                        CityName: data.Address?.CityName,
                        DistrictName: data.Address?.DistrictName,
                        WardName: data.Address?.WardName,
                      })
                    })
                  }}
                  placeholder='Chọn địa bàn phát'
                  filterOption={compareLabelMatch}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='Imei'
                label='IMEI'
                rules={[
                  {
                    required: true,
                    message: 'IMEI không được bỏ trống',
                    type: 'string',
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder='Nhập IMEI' />
              </Form.Item>
              <Form.Item
                name='Longitude'
                label='Kinh độ'
                initialValue={LongitudeByTech}
                rules={[
                  {
                    required: true,
                    message: 'Kinh độ không được bỏ trống',
                  },
                  {
                    type: 'number',
                    min: 102.13596451123415,
                    max: 117.15006168913696,
                    message:
                      'Giá trị kinh độ chỉ hỗ trợ địa điểm ở Việt Nam và một số vùng lân cận',
                  },
                ]}
              >
                <InputNumber style={{width: '100%'}} min={0} placeholder='Nhập kinh độ' />
              </Form.Item>
              <Form.Item
                name='CustomerPhone'
                label='Số điện thoại'
                rules={[
                  {
                    pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/g,
                    message: 'Số điện thoại không đúng định dạng',
                  },
                ]}
              >
                <Input placeholder='Nhập số điện thoại' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='ManufactureId'
                label='Nhà cung cấp'
                rules={[
                  {
                    required: true,
                    message: 'Nhà cung cấp không được bỏ trống',
                  },
                ]}
              >
                <Select
                  options={manufactureList?.Data?.map((item) => ({
                    label: item.Info,
                    value: item.Id,
                  }))}
                  placeholder='Chọn nhà cung cấp'
                  filterOption={compareLabelMatch}
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name='Latitude'
                label='Vĩ độ'
                initialValue={LatitudeByTech}
                rules={[
                  {
                    required: true,
                    message: 'Vĩ độ không được bỏ trống',
                  },
                  {
                    type: 'number',
                    min: 7.5820688268599925,
                    max: 23.401362513253336,
                    message: 'Giá trị vĩ độ chỉ hỗ trợ địa điểm ở Việt Nam và một số vùng lân cận',
                  },
                ]}
              >
                <InputNumber style={{width: '100%'}} min={0} placeholder='Nhập vĩ độ' />
              </Form.Item>
            </Col>
          </Row>
          <Typography.Title level={5} style={{color: '#A6A6A6'}}>
            Thông tin bổ sung
          </Typography.Title>{' '}
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item name='ServiceType' label='Gói dịch vụ 1' initialValue={0}>
                <Select options={ServiceTypeList} placeholder='Chọn gói dịch vụ' />
              </Form.Item>
              <Form.Item
                name='CityId'
                label='Tỉnh/Thành phố'
                initialValue={currentUser && currentUser?.CityIds ? currentUser?.CityIds[0] : ''}
                rules={[
                  {
                    required: true,
                    message: 'Tỉnh/Thành phố không được bỏ trống',
                  },
                ]}
              >
                <Select
                  // disabled
                  allowClear
                  options={cityList?.map((item) => ({
                    label: item.CityName,
                    value: item.CityId,
                  }))}
                  placeholder='Chọn tỉnh thành phố'
                  onChange={(Id: string, value: any) => {
                    form.resetFields(['DistrictId', 'WardId'])
                    setDistrictId('')
                    setCityName(value?.label)
                  }}
                  filterOption={compareLabelMatch}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='InitTime'
                label='Ngày nghiệm thu'
                rules={[
                  ({getFieldValue}) => {
                    return {
                      validator: (_, value) => {
                        if (
                          !value ||
                          !getFieldValue('WarrantyTime') ||
                          moment(getFieldValue('WarrantyTime')).isAfter(moment(value))
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error('Ngày nghiệm thu phải nhỏ hơn ngày lắp đặt')
                        )
                      },
                    }
                  },
                ]}
              >
                <DatePicker
                  style={{width: '100%'}}
                  format={'DD/MM/YYYY'}
                  placeholder='Chọn ngày lắp đặt'
                  onChange={(e) => form.setFieldValue('WarrantyTime', '')}
                />
              </Form.Item>
              <Form.Item
                name='DistrictId'
                label='Quận/Huyện'
                rules={[
                  {
                    // required: true,
                    message: 'Quận/Huyện không được bỏ trống',
                  },
                ]}
              >
                <Select
                  // disabled
                  allowClear
                  options={
                    districtList?.map((item) => ({
                      label: item.DistrictName,
                      value: item.DistrictId,
                    })) as DefaultOptionType[]
                  }
                  placeholder='Chọn quận/huyện'
                  onChange={(label: string, value: any) => {
                    setDistrictName(value?.label)
                    form.resetFields(['WardId'])
                  }}
                  filterOption={compareLabelMatch}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='WarrantyTime'
                label='Ngày hết hạn gói dịch vụ'
                rules={[
                  ({getFieldValue}) => {
                    return {
                      validator: (_, value) => {
                        if (!value || moment(getFieldValue('InitTime')).isBefore(moment(value))) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error('Ngày hết hạn gói dịch vụ phải lớn hơn ngày lắp đặt')
                        )
                      },
                    }
                  },
                ]}
              >
                <DatePicker
                  style={{width: '100%'}}
                  format={'DD/MM/YYYY'}
                  placeholder='Chọn ngày bảo hành'
                />
              </Form.Item>
              <Form.Item name='WardId' label='Xã/Phường'>
                <Select
                  // disabled
                  allowClear
                  options={
                    wardList?.map((item) => ({
                      label: item.WardName,
                      value: item.WardId,
                    })) as DefaultOptionType[]
                  }
                  placeholder='Chọn xã/phường'
                  onChange={(label: string, value: any) => {
                    setWardName(value?.label)
                  }}
                  filterOption={compareLabelMatch}
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
)

export default DeviceModal

import {SearchOutlined, SoundOutlined} from '@ant-design/icons'
import {Button, Col, Input, InputNumber, InputRef, Modal, Row, Slider, Space} from 'antd'
import 'antd/dist/antd.css'
import {ColumnsType, ColumnType} from 'antd/lib/table/interface'
import moment from 'moment'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import Highlighter from 'react-highlight-words'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {TableComponent} from '../../../../../components'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTCardBody} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {Role} from '../../../../modules/auth/core/_models'
import DeviceModal from '../../thietbi-list/components/header/DeviceModal'
import {BangDienTuPagination} from '../components/pagination/BangDienTuPagination'
import {useListViewBDT} from '../core/ListViewProviderBDT'
import {useQueryRequest} from '../core/QueryRequestProviderBDT'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProviderBDT'
import {Address} from '../core/_models'
import {checkDeleteDevice, deleteDeviceInfo} from '../core/_requests'
import {mapDeviceStatus} from '../../../../../enum/deviceStatus.enum'
import {controlVolumeDeviceInfo} from '../../../MAP_New2/core/_requests'
type Props = {}

const BangDienTuTable: FC<Props> = () => {
  const {setItemIdForLed, setItemIdForSettingLed, setItemIdForUpdateLed} = useListViewBDT()
  const manufactures = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data: any = useMemo(() => manufactures, [manufactures])
  const {state} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const {auth, setDeviceType, setLstImei, currentUser, lstImei} = useAuth()

  const DEVICE_DELETE = currentUser?.Roles.find((item: Role) => {
    return item.Value === 11
  })
  const DEVICE_CONTROL = currentUser?.Roles.find((item: Role) => {
    return item.Value === 13
  })
  const DEVICE_EDIT = currentUser?.Roles.find((item: Role) => {
    return item.Value === 11
  })

  const [modal, setModal] = useState<{showModal: boolean; id?: string}>({
    showModal: false,
    id: undefined,
  })

  const openModal = (id: string) => {
    setModal({showModal: true, id})
  }

  const handleCancel = () => {
    setModal({showModal: false})
  }

  interface DataType {
    key: string
    Id: string
    State: number
    Imei: string
    GroupName: string
    Address: Address
    LastUpdate: Date
    Info: string
    ManufactureName: string
    GsmInfo: string
    Firmware: string
    Volumne: number
    DeviceType: number
    DeviceError: string
  }
  useEffect(() => {
    MenuComponent.reinitialization()
    return () => {
      setDeviceType(undefined)
      setLstImei([])
    }
  }, [])

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  type DataIndex = keyof DataType
  const [FromTime, setFromTime] = useState<string | undefined>()
  const [ToTime, setToTime] = useState<string | undefined>()
  const [isValid, setIsValid] = useState<boolean>(true)
  const {updateState} = useQueryRequest()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [imei, setImei] = useState('')
  const [inputValue, setInputValue] = useState(30)

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          //onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          onPressEnter={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
          style={{marginBottom: 8, display: 'block'}}
        />
        <div className='row d-flex'>
          <div className='col-6'>
            <Button
              type='primary'
              onClick={() => updateFilter(dataIndex, selectedKeys[0]?.toString()?.trim() || '')}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-6'>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters)
                updateFilter(dataIndex, '')
              }}
              size='small'
              // style={{ width: 90 }}
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getColumnSearchTime = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <div className='row d-flex'>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Từ ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Từ ngày'
              name='FromTime'
              value={FromTime}
              onChange={(e) => {
                if (ToTime && new Date(e.target.value) > new Date(ToTime)) setIsValid(false)
                else setIsValid(true)
                setFromTime(e.target.value)
              }}
            />
            {/* {!isValidTimeFrom && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    Từ ngày không được để trống
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Đến ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Đến ngày'
              name='ToTime'
              value={ToTime}
              onChange={(e) => {
                if (FromTime && new Date(e.target.value) < new Date(FromTime)) setIsValid(false)
                else setIsValid(true)
                setToTime(e.target.value)
              }}
            />
            {/* {!isValidTimeTo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    Đến ngày không được để trống
                  </span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className='row'>
          {!isValid && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  Đến ngày phải lớn hơn Từ ngày
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='row d-flex justify-content-center'>
          <div className='col-4'>
            <Button
              type='primary'
              onClick={() => {
                // if (!FromTime) setIsValidTimeFrom(false)
                // else setIsValidTimeFrom(true)
                // if (!ToTime) setIsValidTimeTo(false)
                // else setIsValidTimeTo(true)
                let tmp: any = {
                  ...((state.filter as object) || {
                    Location: {
                      CityId: '',
                      DistrictId: '',
                    },
                    FromTime: FromTime || null,
                    ToTime: ToTime || null,
                    Info: '',
                    Imei: '',
                    ManufactureName: '',
                    Address: '',
                    DeviceGroupName: '',
                    State: null,
                    GroupIds: currentUser?.DeviceGroupIds,
                    ListImei: lstImei,
                  }),
                }
                tmp['FromTime'] = FromTime ? `${FromTime}T00:00:00Z` : null
                tmp['ToTime'] = ToTime ? `${ToTime}T23:59:59Z` : null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
            >
              Tìm
            </Button>
          </div>
          <div className='col-4'>
            <Button
              onClick={() => {
                setFromTime('')
                setToTime('')
                clearFilters && handleReset(clearFilters)
                let tmp: any = {
                  ...((state.filter as object) || {
                    Location: {
                      CityId: '',
                      DistrictId: '',
                    },
                    FromTime: FromTime || null,
                    ToTime: ToTime || null,
                    Info: '',
                    Imei: '',
                    ManufactureName: '',
                    Address: '',
                    DeviceGroupName: '',
                    State: null,
                    GroupIds: currentUser?.DeviceGroupIds,
                    ListImei: lstImei,
                  }),
                }
                tmp['FromTime'] = null
                tmp['ToTime'] = null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const updateFilter = (dataIndex: any, value: any) => {
    let tmp: any = {
      ...((state.filter as object) || {
        Location: {
          CityId: '',
          DistrictId: '',
        },
        Info: '',
        Imei: '',
        ManufactureName: '',
        Address: '',
        DeviceGroupName: '',
        State: null,
        GroupIds: currentUser?.DeviceGroupIds,
        ListImei: lstImei,
        FromTime: null,
        ToTime: null,
      }),
    }
    tmp[dataIndex] = value
    updateState({
      filter: tmp,
      ...initialQueryState,
    })
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      key: 'index',
      render: (text, record, index) => index + 1 + (state.currentPage - 1) * state.pageSize,
      align: 'center',
      width: '5%',
    },
    {
      title: 'Tên thiết bị',
      key: 'Info',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('Info'),
      render: (record: DataType) => {
        return (
          <span
            className='TextTitle'
            style={{fontStyle: 'underline'}}
            title={record.Info}
            onClick={() => {
              if (DEVICE_EDIT) {
                openModal(record.Id)
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Bạn không có quyền chỉnh sửa thiết bị',
                  text: ' ',
                  confirmButtonText: 'Đóng lại',
                })
              }
            }}
          >
            {record.Info}
          </span>
        )
      },
    },
    {
      title: 'IMEI',
      dataIndex: 'Imei',
      key: 'Imei',
      ...getColumnSearchProps('Imei'),
    },
    {
      title: 'Trạng thái',
      align: 'center',
      key: 'State',
      render: (record: DataType) => {
        if (record.DeviceError) {
          return (
            <span
              style={{
                borderRadius: 40,
                padding: '3px 20px',
                fontWeight: 600,
                width: 'fit-content',
                color: '#f1416c',
                backgroundColor: 'rgb(255, 232, 239)',
              }}
            >
              {record.DeviceError}
            </span>
          )
        } else {
          return (
            <span
              style={{
                borderRadius: 40,
                padding: '3px 20px',
                color: mapDeviceStatus.get(record.State)?.color,
                backgroundColor: mapDeviceStatus.get(record.State)?.bgColor,
                fontWeight: 600,
                width: 'fit-content',
              }}
            >
              {mapDeviceStatus.get(record.State)?.label}{' '}
            </span>
          )
        }
      },
    },
    {
      title: 'ÂM LƯỢNG',
      key: 'Volumne',
      align: 'center',
      ...getColumnSearchProps('Volumne'),
      width: '15%',
      render: (record: DataType) => {
        return (
          <Space>
            {record.Volumne ? `${record.Volumne}%` : '0'}
            <Button
              title={'Âm lượng'}
              type='text'
              icon={<SoundOutlined />}
              onClick={() => {
                setIsModalOpen(true)
                setImei(record.Imei)
                setInputValue(record.Volumne)
              }}
            />
          </Space>
        )
      },
    },
    {
      title: 'ĐỊA BÀN PHÁT',
      dataIndex: 'GroupName',
      align: 'center',
      key: 'GroupName',
      className: 'white-space col-group-name',
      ...getColumnSearchProps('GroupName'),
      render: (GroupName: string) => {
        return <span title={GroupName}>{GroupName}</span>
      },
    },
    {
      title: 'NHÀ CUNG CẤP',
      dataIndex: 'ManufactureName',
      key: 'ManufactureName',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('ManufactureName'),
      render: (ManufactureName: string) => {
        return <span title={ManufactureName}>{ManufactureName}</span>
      },
    },
    {
      title: 'VỊ TRÍ',
      dataIndex: 'Address',
      key: 'Address',
      className: 'white-space col-Address-M',
      ...getColumnSearchProps('Address'),
      render: (Address: Address) => {
        return (
          <span title={`${Address.WardName}, ${Address.DistrictName}, ${Address.CityName}`}>
            {Address.WardName}, {Address.DistrictName}, {Address.CityName}
          </span>
        )
      },
    },

    {
      title: 'CẬP NHẬT',
      dataIndex: 'LastUpdate',
      key: 'LastUpdate',
      align: 'center',
      ...getColumnSearchTime('LastUpdate'),
      render: (LastUpdate) => {
        return <p className='center m-0'>{moment(LastUpdate).format('DD/MM/YYYY HH:mm')}</p>
      },
    },
    {
      title: 'HÀNH ĐỘNG',
      key: 'Action',
      align: 'center',
      width: '12%',
      fixed: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <i
            title='Chi tiết'
            className='bi bi-info-circle fs-1 icon-action'
            onClick={() => {
              setItemIdForLed(record)
            }}
          ></i>
          {/* {DEVICE_CONTROL && record.DeviceType != 4 && (
            <i
              title='Cài đặt hiển thị'
              className='bi bi-tv fs-1 icon-action'
              onClick={() => {
                setItemIdForSettingLed(record.Id)
              }}
            ></i>
          )} */}
          {DEVICE_DELETE && (
            <i
              title='Xóa'
              className='bi bi-trash text-danger fs-1 icon-action'
              onClick={() => {
                FCheckDelete(record)
              }}
              style={{
                background: '#FFE8EF',
              }}
            ></i>
          )}
        </Space>
      ),
    },
  ]

  const queryClient = useQueryClient()

  const FCheckDelete = (record: DataType) => {
    CheckdeleteItem.mutateAsync(record)
  }

  const CheckdeleteItem = useMutation((record: DataType) =>
    checkDeleteDevice(auth?.configs, record.Id, (id: any, message: any) => {
      Swal.fire({
        title: message,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Quay lại',
        confirmButtonText: 'Xác nhận',
        confirmButtonColor: 'red',
        width: 500,
        reverseButtons: true,
      }).then(async function (result) {
        if (result.value) {
          await deleteItem.mutateAsync(id)
        }
      })
    })
  )

  const deleteItem = useMutation((id: any) => deleteDeviceInfo(auth?.configs, id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries('delete-deviceInfo')
      Swal.fire({
        icon: 'success',
        title: 'Xóa thành công',
        text: ' ',
        showConfirmButton: false,
        timer: 1500,
      })
      cancel(true)
    },
    onError: (error: any) => {
      Swal.fire({
        icon: 'error',
        title: 'Xóa thất bại',
        text: error.response.data,
        confirmButtonText: 'Đóng lại',
      })
    },
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }
  interface VolumeInfo {
    Imei: string
    Volume: number
  }
  const handleOk = () => {
    const payload: VolumeInfo = {
      Imei: imei,
      Volume: inputValue,
    }

    try {
      controlVolumeDeviceInfo(
        auth?.configs,
        payload.Imei || '',
        payload.Volume,
        (res: any) => {
          Swal.fire({
            icon: 'success',
            title: 'Cập nhật thông tin thành công',
            text: ' ',
            showConfirmButton: false,
            timer: 1500,
          }).then((_) => {
            refetch()
          })
          // cancel()
        },
        (message: any) => {
          Swal.fire({
            icon: 'error',
            title: 'Cập nhật thông tin thất bại',
            text: ' ',
            confirmButtonText: 'Đóng lại',
            confirmButtonColor: '#009ef7',
          })
        }
      )
    } catch (ex) {
      console.error(ex)
    } finally {
      refetch()
      setIsModalOpen(false)
    }
  }
  const handleCancelVolumne = () => {
    setIsModalOpen(false)
  }
  const onChange = (newValue: any) => {
    setInputValue(newValue)
  }

  if (
    manufactures &&
    manufactures.length > 0 &&
    ('TextContent' in manufactures[0] || 'Content' in manufactures[0])
  )
    return null
  return (
    <KTCardBody className='py-4'>
      <TableComponent columns={columns} dataSource={data} />
      <BangDienTuPagination />
      {isLoading && <Loading />}
      {modal.showModal && (
        <DeviceModal
          handleCancel={handleCancel}
          isModalOpen={modal.showModal}
          id={modal.id}
          isElectronicBoard={true}
        />
      )}
      <Modal
        className='nguoi-dung-body'
        title='Thay đổi âm lượng'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancelVolumne}
      >
        <Row className='al-center'>
          <Col span={20}>
            <Slider
              min={0}
              max={100}
              onChange={onChange}
              value={inputValue || 0}
              // tooltip={{open: true}}
            />
          </Col>
          <Col span={4}>
            <InputNumber min={0} max={100} value={inputValue || 0} onChange={onChange} />
          </Col>
        </Row>
      </Modal>
    </KTCardBody>
  )
}

export {BangDienTuTable}

import axios, {AxiosResponse} from 'axios'
import {DynamicConfig} from '../../../../../configuration/config'
import {ID, QueryState, Response} from '../../../../../_metronic/helpers'
import {District, Ward} from '../../../MAP_New2/core/_models'
import {Address, AddressQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ADDRESS_URL = `${API_URL}/location`
const location = '/location'

const getAddress = (configs: DynamicConfig, state: QueryState): Promise<AddressQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${location}/get-location`, {headers: state})
    .then((d: AxiosResponse<AddressQueryResponse>) => d.data)
}

const getAddressSearch = (configs: DynamicConfig): Promise<AddressQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${location}/get-location`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<AddressQueryResponse>) => d.data)
}

const createAddress = (configs: DynamicConfig, Address: Address): Promise<Address | undefined> => {
  return axios
    .post(`${configs.apiUrl}${location}/create-location`, Address)
    .then((response: AxiosResponse<Response<Address>>) => response.data)
    .then((response: Response<Address>) => response.data)
}

const createAddressDistrict = (
  configs: DynamicConfig,
  Address: Address
): Promise<Address | undefined> => {
  return axios
    .put(`${configs.apiUrl}${location}/create-locationDistrict`, Address)
    .then((response: AxiosResponse<Response<Address>>) => response.data)
    .then((response: Response<Address>) => response.data)
}

const updateAddressDistrict = (
  configs: DynamicConfig,
  Address: Address
): Promise<Address | undefined> => {
  return axios
    .put(`${configs.apiUrl}${location}/update-locationDistrict`, Address)
    .then((response: AxiosResponse<Response<Address>>) => response.data)
    .then((response: Response<Address>) => response.data)
}

const createAddressWard = (
  configs: DynamicConfig,
  Address: Address
): Promise<Address | undefined> => {
  return axios
    .post(`${configs.apiUrl}${location}/create-locationWard`, Address)
    .then((response: AxiosResponse<Response<Address>>) => response.data)
    .then((response: Response<Address>) => response.data)
}

const updateAddressWard = (
  configs: DynamicConfig,
  Address: Address
): Promise<Address | undefined> => {
  return axios
    .post(`${configs.apiUrl}${location}/update-locationWard`, Address)
    .then((response: AxiosResponse<Response<Address>>) => response.data)
    .then((response: Response<Address>) => response.data)
}

const updateAddress = (configs: DynamicConfig, Address: Address): Promise<Address | undefined> => {
  return axios
    .put(`${configs.apiUrl}${location}/update-location`, Address)
    .then((response: AxiosResponse<Response<Address>>) => response.data)
    .then((response: Response<Address>) => response.data)
}

const deleteAddress = (configs: DynamicConfig, CityId: string, callback: any): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${location}/delete-locationCity`, {CityId})
    .then(() => callback.onSuccess())
    .catch(() => callback.onError())
}

const deleteAddressDistrict = (
  configs: DynamicConfig,
  CityId: string,
  DistricId: string,
  callback: any
): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${location}/delete-locationDistric`, {CityId, DistricId})
    .then(() => callback.onSuccess())
    .catch(() => callback.onError())
}

const deleteAddressWard = (
  configs: DynamicConfig,
  CityId: string,
  DistrictId: string,
  WardId: string,
  callback: any
): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${location}/delete-locationWard`, {CityId, DistrictId, WardId})
    .then(() => callback.onSuccess())
    .catch(() => callback.onError())
}

const getAddressById = (configs: DynamicConfig, CityId: string): Promise<AddressQueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${location}/get-locationByCityId`, {CityId})
    .then((response: AxiosResponse<AddressQueryResponse>) => response.data)
}

const getAddressByDistrictId = (
  configs: DynamicConfig,
  CityId: string,
  DistrictId: string
): Promise<AddressQueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${location}/get-locationByDistrictId`, {CityId, DistrictId})
    .then((response: AxiosResponse<AddressQueryResponse>) => response.data)
}

const getAddressByWardId = (
  configs: DynamicConfig,
  CityId: string,
  DistrictId: string,
  WardId: string
): Promise<AddressQueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${location}/get-locationByWardId`, {CityId, DistrictId, WardId})
    .then((response: AxiosResponse<AddressQueryResponse>) => response.data)
}

const deleteSelectedAddresss = (configs: DynamicConfig, userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${configs.apiUrl}${location}/${id}`))
  return axios.all(requests).then(() => {})
}

const exportLocation = (configs: DynamicConfig): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${location}/export-location`, {}, {responseType: 'blob'})
    .then((d: AxiosResponse<Blob>) => d.data)
}

const getDistrictByCityId = (configs: DynamicConfig, id: string): Promise<Array<District>> => {
  return axios
    .post(`${configs.apiUrl}${location}/get-districtByCityId`, {
      CityId: id,
    })
    .then((response: AxiosResponse<Array<District>>) => response.data)
}

const getDeviceExpired = (configs: DynamicConfig): Promise<Array<any>> => {
  return axios
    .get(`${configs.apiUrl}/DeviceInfo/get-DeviceExpired`)
    .then((response: AxiosResponse<Array<any>>) => response.data)
}

const getWardByDistrictId = (
  configs: DynamicConfig,
  cityId: string,
  districtId: string
): Promise<Array<Ward>> => {
  return axios
    .post(`${configs.apiUrl}${location}/get-wardByDistrictId`, {
      CityId: cityId,
      DistrictId: districtId,
    })
    .then((response: AxiosResponse<Array<Ward>>) => response.data)
}

export {
  exportLocation,
  getAddress,
  getAddressById,
  updateAddressDistrict,
  deleteAddress,
  updateAddressWard,
  getAddressByWardId,
  getAddressByDistrictId,
  deleteAddressDistrict,
  createAddressWard,
  createAddressDistrict,
  deleteAddressWard,
  deleteSelectedAddresss,
  createAddress,
  updateAddress,
  getAddressSearch,
  getDistrictByCityId,
  getWardByDistrictId,
  getDeviceExpired,
}
